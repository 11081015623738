@import url("https://fonts.googleapis.com/css2?family=Alexandria,sans-serif;:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas Neue,cursive;:wght@300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #1d2229 #fff;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

:root {
  --one: #ffa800;
  --two: #ffa800;
  --three: #1d2229;
  --four: #6a6a6a;
  --five: #2c323a;
  --six: #f0f0f0;
  --seven: #d2d2d2;
  --white: #fff;
  --yellow: #ffd058;
  --main-transition: 0.3s;
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
}

body {
  font-family: "Alexandria", sans-serif;
}

.container {
  width: 90%;
  margin: 0 auto;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.5;
}

.margin-sections {
  margin-top: 90px;
}

@media (max-width: 900px) {
  .margin-sections {
    margin-top: 70px;
  }
}

.sections-padding {
  padding: 2rem 0;
}

.head {
  font-family: "Bebas Neue", cursive;
  font-weight: 900;
  font-size: 140px;
}

.main-heading {
  color: var(--white);
  --x-offset: -0.0625em;
  --y-offset: 0.0625em;
  --stroke: 0.025em;
  --background-color: black;
  --stroke-color: lightblue;
  /* font-family: Bebas Neue; */
  font-weight: 900;
  font-size: 2.75rem;
  line-height: 3.625rem;
  font-family: "Bebas Neue", cursive;
  font-weight: 900;
}

.sub-heading {
  margin-bottom: 24px;
  text-align: left;
  font-size: 20px;
}

.main-background {
  text-align: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Global Styles For All  Button*/
button {
  position: relative;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  z-index: 1;
}

button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  z-index: -1;
}

button:hover::before {
  width: 100%;
}

/* Global P  */
.paragraph {
  margin-bottom: 8px;
  letter-spacing: 2px;
  color: var(--two);
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 600;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: var(--white);
}

*::-webkit-scrollbar-track:hover {
  background-color: var(--white);
}

*::-webkit-scrollbar-track:active {
  background-color: var(--white);
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--three);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #1d2229;
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--three);
}

.mt-48 {
  margin-top: 48px;
}