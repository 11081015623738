.card_view {
  margin-top: -24px;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 15em;
  padding: 1em;
  margin: 2em;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex !important;
  flex-wrap: wrap !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card h1,
h3,
h4 {
  margin: 5px 0 5px 0;
}

.card_image {
  margin-left: -10px;
  width: 230px !important;
  justify-content: center;
}

.card_image_creatine {
  height: 200px;
  margin-left: -10px;
  width: 230px !important;
  justify-content: center;
}

.products {
  color: var(--three);
}

.products_head {
  text-align: center;
}

.card_title {
  font-size: 14px;
}

.card_info {
  font-size: 10px;
    font-weight: 100;
}

.buy_btn {
  margin-right: 16px;
  background-color: #ffa800;
}

.added {
  margin-right: 24px;
  background-color: #000;
  color: #f4f4f4;
}

.added_disabled{
  margin-right: 24px;
  background-color: #000;
  color: #f4f4f4;
  cursor: not-allowed;
  pointer-events: none;
}

.cart_btn{
  margin-right: 16px;
  background-color: #8a600e;
  color: #fff;
  margin-top: 8px;
}

.buy_btn_blck {
  margin-right: 24px;
  margin-top: 8px;
  background-color: #000;
  color:#f4f4f4;
}

.addsubtract_btn{
  text-align: center;
}

.buy_btn_decrement{
  margin-right: 24px;
  background-color: #ffa800;
  margin-left: 24px;
}

.card_title_mt72 {
  margin-top: 63px !important;
  font-size: 14px;
}

.search_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 16px;
}

.search_input {
  padding: 5px 5px 5px 10px;
  width: 400px;
  height: 40px;
}

.search_button {
  margin-left: -16px;
  padding: 8px;
  background-color: #000;
  width: 100px;
  font-size: large;
  color: #fff;
  transition: 0.5s linear;
  height: 45px;
}

.share_icon {
  font-size:24px;
  margin-top: -24px;
  position: relative;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.img_share {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}